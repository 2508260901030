import React from 'react';
import { Link } from 'gatsby';
import './404.css';
import NotFoundImage from '../assets/not-found.svg';
import Layout from '../components/layout';

const ContentNotFound = () => {
  return (
    <Layout>
      <div className="not-found-container">
        <main className="not-found-content">
          <img src={NotFoundImage} alt="Página não encontrada" className="not-found-image" />
          <h1 className='title-h1'>Conteudo não encontrado!</h1>
          <p className='text-p'>Parece que você está procurando algo que não está mais aqui. Mas não se preocupe, podemos te ajudar a encontrar o que você precisa.</p>
          <Link to="/" className="home-link">Voltar para a página inicial</Link>
        </main>
      </div>
    </Layout>
  );
};

export default ContentNotFound;